import {Action, ActionCreator} from "redux";
import {StatusState} from "./statusReducer";

export enum StatusActionTypes {
    SET_STATUS = "SET_STATUS"
}

export interface SetStatus extends Action {
    type: StatusActionTypes.SET_STATUS
    payload: {
        state: StatusState
    }
}

export type StatusActions = SetStatus

export const setStatus: ActionCreator<SetStatus> = (
    statusState: StatusState
) => ({
    type: StatusActionTypes.SET_STATUS,
    payload: {
        state: {
            ...statusState
        }
    }
})
