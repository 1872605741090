import { OAuthPopup } from "@tasoskakour/react-use-oauth2";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as React from "react";
import {Home} from "./pages/Home";
import MenuBarComponent from "./MenuBarComponent";
import {theme} from "./theme";
import {Box, ThemeProvider} from "@mui/material";
import MyTournaments from "./pages/admin/MyTournaments";
import 'react-virtualized/styles.css';
import TournamentPage from "./pages/TournamentPage";
import StatusGrowl from "./StatusGrowl";
import {GoogleOAuthProvider} from "@react-oauth/google";
// import {HistoryRouter} from "redux-first-history/rr6";
// import {reduxHashHistory} from "../store";

export const Application = () => {

    return (
        <ThemeProvider theme={theme}>
            <GoogleOAuthProvider clientId={"922748672818-2dcf8981sgnl84hakpre5e7m6a9jmv4k.apps.googleusercontent.com"}>
                <BrowserRouter>
                    <div style={{height: "100%"}}>
                        <StatusGrowl/>
                        <MenuBarComponent/>
                        <Box component={"main"} height={"calc(100% - 64px)"} sx={{backgroundColor: 'secondary.main', marginTop: '15px'}} >
                            <Routes>
                                {/*<Route element={<OAuthPopup/>} path="/callback" />*/}
                                <Route element={<Home/>} path="/" />
                                <Route element={<MyTournaments/>} path="/admin/tournaments" />
                                <Route element={<TournamentPage/>} path="/tournaments/:tournamentId" />
                            </Routes>
                        </Box>
                    </div>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </ThemeProvider>
    );
};
