import * as React from "react";
import {useSelector} from "react-redux";
import {
    Grid,
    IconButton,
    List, ListItem, ListItemText,
} from "@mui/material";
import {useState} from "react";
import {TeamAdapter, TeamResource} from "../../resources/TeamResource";
import TextField from "@mui/material/TextField";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {TournamentResource} from "../../resources/TournamentResource";
import SpiritScoreDialog from "../resuable-components/spirit-score-components/SpiritScoreDialog";
import {SpiritScoreAdapter, SpiritScoreResource} from "../../resources/SpiritScoreResource";
import {selectLanguage} from "../../util/UtilFunctions";

const PublishedTournamentPage: React.FunctionComponent<{tournament: TournamentResource}> = ({tournament}) => {
    const teamAdapter = new TeamAdapter()
    const spiritScoreAdapter = new SpiritScoreAdapter()
    const teams = teamAdapter.actions().useGetAll(tournament.id)
    const stringMap = useSelector(selectLanguage)

    const [spiritDialogInfo, setSpiritDialogOpen] = useState<{opponentTeam: TeamResource, spiritResource: SpiritScoreResource|undefined}|null>(null)
    const [teamCode, setTeamCode] = useState("")
    // const [selectedTeam, setSelectedTeam] = useState<TeamResource|undefined>(undefined)

    const teamQuery = teamAdapter.actions().useGetByCode(tournament.id, teamCode)
    const spiritScoreQuery = spiritScoreAdapter.actions().useGetAll(teamQuery.data?.id)

    React.useEffect(() => {
        teamQuery.refetch().then()
    }, [teamCode])

    const getTeamComboBox = () => (
        <TextField
            size={"small"}
            placeholder={stringMap.yourTeam}
            InputProps={{
                readOnly: true,
                sx: {backgroundColor: "lightgray"}
            }}
            value={teamQuery.data?.name}
        />
    )

    const getTeamCodeTextField = () => (
        <TextField
            size={"small"}
            placeholder={stringMap.teamCode}
            InputProps={{
                sx: {backgroundColor: "white"}
            }}
            onChange={event => {setTeamCode(event.target.value)}}
        />
    )

    const addSpiritButtons = (team: TeamResource) => {
        const spiritButtons = []
        const spiritScores = spiritScoreQuery.data?.filter(value => value.opponentTeam === team.id) ?? []
        for (let i = 0; i < tournament?.maxRatingPerTeam ?? 0; i++) {
            const spiritScore = (i + 1) <= spiritScores.length ? spiritScores[i] : undefined
            if (spiritScore) {
                spiritButtons.push(
                    <IconButton
                        edge="end"
                        disabled={teamQuery.data === undefined || teamQuery.data.id === team.id}
                        onClick={() => setSpiritDialogOpen({opponentTeam: team, spiritResource: spiritScore})}
                    >
                        <EditIcon/>
                    </IconButton>
                )
            } else {
                spiritButtons.push(
                    <IconButton
                        edge="end"
                        disabled={teamQuery.data === undefined || teamQuery.data.id === team.id}
                        onClick={() => setSpiritDialogOpen({opponentTeam: team, spiritResource: undefined})}
                    >
                        <AddIcon/>
                    </IconButton>
                )
            }
        }
        return (
            <div style={{height: "100%"}} >
                {spiritButtons}
            </div>
        )
    }

    return (
        <div style={{height: "100%", width: "100%"}}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <div style={{display: "inline-flex", width: "100%"}}>
                        {getTeamComboBox()}
                        <div style={{marginRight: 20}}/>
                        {getTeamCodeTextField()}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{height: "100%"}} >
                        <List sx={{backgroundColor: "#84D0D2"}}>
                            {teams.data?.map((value, index) => (
                                <ListItem secondaryAction={addSpiritButtons(value)}>
                                    <ListItemText primary={value.name}/>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Grid>
            </Grid>
            <SpiritScoreDialog
                tournamentId={tournament.id}
                open={spiritDialogInfo !== null}
                teamCode={teamCode}
                teamId={teamQuery.data?.id ?? ""}
                teamName={teamQuery.data?.name ?? ""}
                opponentTeam={spiritDialogInfo?.opponentTeam}
                spiritScoreResource={spiritDialogInfo?.spiritResource}
                rateSelves={tournament.rateSelf ?? false}
                handleClose={() => setSpiritDialogOpen(null)}
            />
        </div>
    )

}

export default PublishedTournamentPage
