import {useSelector} from "react-redux";
import {Autocomplete, Divider, Grid, MenuItem, Select, Typography} from "@mui/material";
import {TournamentAdapter, TournamentResource, TournamentStatus} from "../../resources/TournamentResource";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {selectLanguage} from "../../util/UtilFunctions";

export const Home = () => {
    const tournamentAdapter = new TournamentAdapter()
    const stringMap = useSelector(selectLanguage)
    const navigate = useNavigate()

    const [statusFilter, setStatusFilter] = React.useState<TournamentStatus>(TournamentStatus.PUBLISHED)
    const tournaments = tournamentAdapter.actions().useGetAll(statusFilter)

    return (
        <div style={{height: "100%"}}>
            <Grid
                container
                direction={"column"}
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <div style={{width: "100%"}}>
                        <Typography color={"#84D0D2"}>
                            {stringMap.searchTournaments}
                        </Typography>
                    </div>
                    <Autocomplete
                        disablePortal
                        options={tournaments.data ?? []}
                        onChange={(event, value: TournamentResource) => navigate(`/tournaments/${value.id}`)}
                        getOptionLabel={(option: TournamentResource) => option.name}
                        sx={{ width: "90vw"}}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size={"small"}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {backgroundColor: "white"},
                                    startAdornment: (
                                        <div style={{display: "inline-flex"}}>
                                            <Select
                                                size={"small"}
                                                style={{padding: 0}}
                                                value={statusFilter}
                                                onChange={event => setStatusFilter(event.target.value as TournamentStatus)}
                                            >
                                                <MenuItem value={TournamentStatus.PUBLISHED}>{stringMap.published}</MenuItem>
                                                <MenuItem value={TournamentStatus.FINALIZED}>{stringMap.finalized}</MenuItem>
                                            </Select>
                                            <Divider orientation="vertical" style={{padding: 3}} flexItem />
                                        </div>
                                    )
                                }}
                            />}

                    />
                </Grid>
            </Grid>
        </div>
    );
};
