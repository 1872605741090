import * as React from "react";
import {SpiritScoreResource} from "../../../resources/SpiritScoreResource";
import {useSelector} from "react-redux";
import {
    Box,
    Collapse,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextField from "@mui/material/TextField";
import {selectLanguage} from "../../../util/UtilFunctions";

const TeamScoreOverviewTable: React.FunctionComponent<{
    spiritScores: SpiritScoreResource[],
    ownRatings: boolean
}> = ({spiritScores, ownRatings}) => {
    const stringMap = useSelector(selectLanguage)

    function Row(props: { score: SpiritScoreResource }) {
        const { score } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                        {ownRatings ? score.opponentTeamName : score.ratingTeamName}
                    </TableCell>
                    <TableCell align="right">{ownRatings ? score.ownRuleKnowledge : score.ruleKnowledge}</TableCell>
                    <TableCell align="right">{ownRatings ? score.ownAvoidBodyContact : score.avoidBodyContact}</TableCell>
                    <TableCell align="right">{ownRatings ? score.ownFairMinded : score.fairMinded}</TableCell>
                    <TableCell align="right">{ownRatings ? score.ownPositiveAttitude : score.positiveAttitude}</TableCell>
                    <TableCell align="right">{ownRatings ? score.ownCommunication : score.communication}</TableCell>
                    <TableCell align="right">{
                        (ownRatings ? score.ownRuleKnowledge : score.ruleKnowledge) +
                        (ownRatings ? score.ownAvoidBodyContact : score.avoidBodyContact) +
                        (ownRatings ? score.ownFairMinded : score.fairMinded) +
                        (ownRatings ? score.ownPositiveAttitude : score.positiveAttitude) +
                        (ownRatings ? score.ownCommunication : score.communication)
                    }</TableCell>
                    <TableCell>
                        <IconButton
                            size="small"
                            disabled={score.comment === ""}
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography color={"primary"} variant={"caption"}>{stringMap.comment}</Typography>
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    value={score.comment}
                                    InputProps={{
                                        readOnly: true,
                                        sx: {backgroundColor: "lightgray"}
                                    }}
                                    variant="outlined"
                                />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>{stringMap.opponent}</TableCell>
                        <TableCell align="right">{stringMap.ruleKnowledge}</TableCell>
                        <TableCell align="right">{stringMap.avoidBodyContact}</TableCell>
                        <TableCell align="right">{stringMap.fairMinded}</TableCell>
                        <TableCell align="right">{stringMap.positiveAttitude}</TableCell>
                        <TableCell align="right">{stringMap.communication}</TableCell>
                        <TableCell align="right">{stringMap.total}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {spiritScores.map((row) => (
                        <Row key={row.id} score={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TeamScoreOverviewTable
