import * as React from "react";
import {
    Grid,
} from "@mui/material";
import {TeamAdapter} from "../../resources/TeamResource";
import {useParams} from "react-router-dom";
import {TournamentAdapter, TournamentResource} from "../../resources/TournamentResource";
import FinalizedTeamAccordion from "../resuable-components/team-components/FinalizedTeamAccordion";

const FinalizedTournamentPage: React.FunctionComponent<{tournament: TournamentResource}> = ({tournament}) => {
    const { tournamentId } = useParams()
    const tournamentAdapter = new TournamentAdapter()
    const teamAdapter = new TeamAdapter()
    const teams = teamAdapter.actions().useGetAll(tournamentId)
    const scores = tournamentAdapter.actions().useGetScores(tournamentId)

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div style={{height: "100%", width: "100%"}}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <div style={{height: "100%"}} >
                        {scores.data?.sort((a, b) => a.averageScore <= b.averageScore ? 1 : -1).map((score, index) => (
                            <FinalizedTeamAccordion
                                key={score.teamName}
                                team={teams.data.find(value => value.id === score.teamId)}
                                selfRated={tournament.rateSelf}
                                score={score}
                                index={index}
                                handleChange={handleChange}
                                expanded={expanded}
                            />
                        ))}
                    </div>
                </Grid>
            </Grid>
        </div>
    )

}

export default FinalizedTournamentPage
