import * as React from "react";
import {SpiritScoreResource} from "../../../resources/SpiritScoreResource";
import {TeamResource} from "../../../resources/TeamResource";
import {useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import SpiritScoreComponent from "./SpiritScoreComponent";
import {selectLanguage} from "../../../util/UtilFunctions";

const SpiritScoreDialog: React.FunctionComponent<{
    open: boolean,
    tournamentId: string,
    teamCode: string,
    teamId: string,
    teamName: string,
    opponentTeam: TeamResource | undefined,
    spiritScoreResource: SpiritScoreResource | undefined,
    rateSelves: boolean,
    handleClose: () => void
}> = ({open, tournamentId, teamCode, teamId, teamName, opponentTeam, spiritScoreResource, rateSelves, handleClose}) => {

    const stringMap = useSelector(selectLanguage)

    if (teamId === "" || opponentTeam === undefined) {
        return <div/>
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle style={{color: "#84D0D2", backgroundColor: "#193B5D"}}>{stringMap.spiritScore}</DialogTitle>
            <DialogContent style={{backgroundColor: "#193B5D"}}>
                <SpiritScoreComponent
                    tournamentId={tournamentId}
                    teamCode={teamCode}
                    teamId={teamId}
                    teamName={teamName}
                    opponentTeam={opponentTeam}
                    spiritScoreResource={spiritScoreResource}
                    rateSelves={rateSelves}
                    handleClose={handleClose}
                />
            </DialogContent>
        </Dialog>
    )
}

export default SpiritScoreDialog
