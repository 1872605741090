import * as React from "react";
import {TournamentAdapter, TournamentResource, TournamentStatus} from "../../../resources/TournamentResource";
import {useSelector} from "react-redux";
import {useState} from "react";
import {CustomAccordion, CustomAccordionDetails, CustomAccordionSummary} from "../CustomAccordian";
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Launch";
import AdminTournamentContent from "./AdminTournamentContent";
import EditTournamentDialog from "./EditTournamentDialog";
import {UserAdapter} from "../../../resources/UserResource";
import {useNavigate} from "react-router-dom";
import { isMobile } from 'react-device-detect';
import {selectLanguage} from "../../../util/UtilFunctions";

const TournamentAccordion: React.FunctionComponent<{
    tournament: TournamentResource,
    expanded: string | false,
    index: number
    handleChange:  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void
}> = ({tournament, expanded, index, handleChange}) => {
    const userAdapter = new UserAdapter()
    const tournamentAdapter = new TournamentAdapter()
    const navigate = useNavigate()
    const user = userAdapter.actions().useGetAuthorizedUser()
    const updateStatus = tournamentAdapter.actions().useAuthorizedChangeStatus()
    const stringMap = useSelector(selectLanguage)

    const [open, setOpen] = useState(false)

    return (
        <div>
            <CustomAccordion
                key={tournament.id}
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
            >
                <CustomAccordionSummary>
                    <Typography noWrap sx={{ width: "50%", flexShrink: 0, textAlign: "left" }}>{tournament.name}</Typography>
                    <Select
                        defaultValue={tournament.status}
                        onChange={event => {
                            updateStatus.mutate({
                                id: tournament.id,
                                parentId: user.data?.id,
                                status: event.target.value as TournamentStatus
                            })
                        }}
                        onClick={(event) => event.stopPropagation()}
                        sx={{backgroundColor: "white", padding: 0, margin: 0, maxHeight: 26}}
                        size={"small"}
                    >
                        <MenuItem onClick={(event) => event.stopPropagation()} value={TournamentStatus.PRIVATE}>{stringMap.private}</MenuItem>
                        <MenuItem onClick={(event) => event.stopPropagation()} value={TournamentStatus.PUBLISHED}>{stringMap.published}</MenuItem>
                        <MenuItem onClick={(event) => event.stopPropagation()} value={TournamentStatus.FINALIZED}>{stringMap.finalized}</MenuItem>
                    </Select>
                    <div style={{flexGrow: 1}}/>
                    <IconButton
                        style={{padding: 0, margin: 0}}
                        size={"small"}
                        onClick={(event) => {
                            event.stopPropagation()
                            setOpen(true)
                        }}
                    >
                        <EditIcon/>
                    </IconButton>
                    <IconButton
                        style={{padding: 0, margin: 0, marginLeft: 6}}
                        size={"small"}
                        onClick={(event) => {
                            navigate(`/tournaments/${tournament.id}`)
                            setOpen(true)
                        }}
                    >
                        <LaunchIcon/>
                    </IconButton>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <div style={{display: "inline-flex", width: "100%", textAlign: "left", justifyContent: isMobile ? "space-between" : "inherit"}}>
                        <FormControlLabel
                            style={{padding: 0, margin: 0, marginRight: 20}}
                            disabled
                            control={<Checkbox color={"secondary"} style={{padding: 0, margin: 0}} size={"small"} checked={tournament.rateSelf} />}
                            label={stringMap.rateSelves}
                        />
                        <Typography sx={{ color: 'text.secondary', textAlign: "left" }}>
                            {stringMap.maxRatingsPerTeam}: {tournament.maxRatingPerTeam}
                        </Typography>
                    </div>
                    <AdminTournamentContent tournamentId={tournament.id}/>
                </CustomAccordionDetails>
            </CustomAccordion>
            <EditTournamentDialog
                tournament={tournament}
                handleClose={() => setOpen(false)}
                open={open}
                userId={user.data.id}
            />
        </div>
    )
}

export default TournamentAccordion
