export const ENStringMap = {
    loggedIn: "Logged In",
    loggedOut: "Logged Out",
    couldNotBeFound: "could not be found",
    couldNotUpdated: "could not be updated",
    couldNotBeAdded: "could not be added",
    couldNotBeDeleted: "could not be deleted",
    tournamentsLabel: "Tournaments",
    myTournamentsLabel: "My Tournaments",
    logout: "Logout",
    createTournament: "Create Tournament",
    createTeam: "Create Team",
    teams: "Teams",
    name: "Name",
    date: "Date",
    code: "Code",
    teamCode: "Team Code",
    noDateSet: "No Date Set",
    maxRatingsPerTeam: "Ratings per Team",
    rateSelves: "Rate Selves",
    editTournamentNameAndDate: "Edit Tournament Name",
    save: "Save",
    delete: "Delete",
    cancel: "Cancel",
    yourTeam: "Your Team",
    searchTournaments: "Search Tournaments",
    category: "category",
    ruleKnowledge: "Rule Knowledge",
    avoidBodyContact: "Body Contact",
    fairMinded: "Fair Minded",
    positiveAttitude: "Positive Attitude",
    communication: "Communication",
    comment: "Comment",
    spiritScore: "Spirit Score",
    status: "Status",
    private: "Private",
    published: "Public",
    finalized: "Finalized",
    averageScore: "Score",
    ownAverageScore: "Own Score",
    opponent: "Opponent",
    receivedScores: "Received Scores",
    ownScores: "Own Scores",
    total: "Total",
    added: "Added",
    updated: "Updated",
    deleted: "Deleted",
    ruleKnowledgeDesc: "Did they purposefully misinterpret the rules? Did they keep to the time limits? When they didn't know the rules, did they show a real willingness to learn?",
    bodyContactDesc: "Did they avoid fouling, contact and dangerous plays? Did the game flow smoothly and was played safely?",
    fairMindednessDesc: "Did they apologized in situations where it was appropriate? Did they inform teammates about any wrong/unnecessary calls?",
    positiveAttitudeDesc: "Were they polite? Did they play with an appropriated intensity regardless of score? Did they leave an overall positive impression during and after the game?",
    communicationDesc: "Did they communicate and listen respectfully? Did they keep discussions to reasonable lengths? Did they get to know us?"
}
