import {Action, ActionCreator} from "redux";
import {PageDetailsState} from "./pageDetailsReducer";

export enum PageDetailsActionTypes {
    SET_PAGE_DETAILS = "SET_PAGE_DETAILS"
}

export interface SetPageDetails extends Action {
    type: PageDetailsActionTypes.SET_PAGE_DETAILS
    payload: {
        state: PageDetailsState
    }
}

export type PageDetailsActions = SetPageDetails

export const setPageDetails: ActionCreator<SetPageDetails> = (
    pageDetailsState: PageDetailsState
) => ({
    type: PageDetailsActionTypes.SET_PAGE_DETAILS,
    payload: {
        state: {
            ...pageDetailsState
        }
    }
})
