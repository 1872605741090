import {useMutation, useQuery, useQueryClient} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {deleteFunction, get, patch, post} from "../APIVersion1";
import {AuthDeleteMutation, AuthUpdateMutation, DeleteMutation, ResourceType, UpdateMutation} from "./types";
import {RootState} from "../../store";
import {handleUnauthorized, selectLanguage} from "../../util/UtilFunctions";
import {setStatus} from "../../store/ui/status/statusActions";

export function ResourceAdapterActions<R>(apiPostFix: string, resourceType: ResourceType) {

    const queryClient = useQueryClient()
    const dispatch = useDispatch()
    const apiToken = useSelector((state: RootState) => `Bearer ${state.persisted.apiToken}`)
    const stringMap = useSelector(selectLanguage)

    const getResource = (id): Promise<R> => {
        return get(`${apiPostFix}/${id}`).then(
            result => {
                if (result.status === 200) {
                    return result.json().then(value => {
                        if (value.hasOwnProperty("date") && value.date !== null) {
                            value.date = new Date(value.date)
                        }
                        return value
                    })
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeFound}`}))
                }
            }
        )
    }

    const getAllWithRelation = (parentId, parentRelation): Promise<R[]> => {
        return get(`${apiPostFix}?parent-id=${parentId}&parent-relation=${parentRelation}`).then(
            result => {
                if (result.status === 200) {
                    return result.json().then(values => {
                        values.forEach(value => {
                            if (value.hasOwnProperty("date") && value.date !== null) {
                                value.date = new Date(value.date)
                            }
                        })
                        return values
                    })
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeFound}`}))
                }
            }
        )
    }

    const getSingleWithRelation = (parentId, parentRelation): Promise<R> => {
        return get(`${apiPostFix}?parent-id=${parentId}&parent-relation=${parentRelation}`).then(
            result => {
                if (result.status === 200) {
                    return result.json().then(value => {
                        if (value.hasOwnProperty("date") && value.date !== null) {
                            value.date = new Date(value.date)
                        }
                        return value
                    })
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeFound}`}))
                }
            }
        )
    }

    const getAll = (parentId): Promise<R[]> => {
        return get(`${apiPostFix}?parent-id=${parentId}`).then(
            result => {
                if (result.status === 200) {
                    return result.json().then(values => {
                        values.forEach(value => {
                            if (value.hasOwnProperty("date") && value.date !== null) {
                                value.date = new Date(value.date)
                            }
                        })
                        return values
                    })
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeFound}`}))
                }            }
        )
    }

    const getSingle = (parentId): Promise<R|null> => {
        return get(`${apiPostFix}?parent-id=${parentId}`).then(
            result => {
                if (result.status === 200) {
                    return result.json().then(value => {
                        if (value.hasOwnProperty("date") && value.date !== null) {
                            value.date = new Date(value.date)
                        }
                        return value
                    })
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeFound}`}))
                }
            }
        )
    }

    const updateResource = (resource, id, teamCode): Promise<R> => {
        return patch(`${apiPostFix}/${id}?team-code=${teamCode}`, resource).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.updated} ${resourceType}`}))
                    return result.json().then()
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotUpdated}`}))
                }
            }
        )
    }

    const authorizedUpdateResource = (resource, id, tournamentId): Promise<R> => {
        let tournamentParam = "";
        if (tournamentId) {
            tournamentParam = `?tournament-id=${tournamentId}`
        }
        return patch(`admin/${apiPostFix}/${id}${tournamentParam}`, resource, apiToken).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.updated} ${resourceType}`}))
                    return result.json().then()
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotUpdated}`}))
                }
            }
        )
    }

    const addOrSetResourceWithRelation = (resource, parentId, parentRelation, teamCode): Promise<R> => {
        return post(`${apiPostFix}?parent-id=${parentId}&parent-relation=${parentRelation}&team-code=${teamCode}`, resource).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.added} ${resourceType}`}))
                    return result.json().then()
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeAdded}`}))
                }
            }
        )
    }

    const addOrSetResource = (resource, parentId, teamCode): Promise<R> => {
        return post(`${apiPostFix}?parent-id=${parentId}&team-code=${teamCode}`, resource).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.added} ${resourceType}`}))
                    return result.json().then()
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeAdded}`}))
                }
            }
        )
    }

    const authorizedAddOrSetResourceWithRelation = (resource, parentId, parentRelation, tournamentId): Promise<R> => {
        let tournamentParam = "";
        if (tournamentId) {
            tournamentParam = `&tournament-id=${tournamentId}`
        }
        return post(`admin/${apiPostFix}?parent-id=${parentId}&parent-relation=${parentRelation}${tournamentParam}`, resource, apiToken).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.added} ${resourceType}`}))
                    return result.json().then()
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                }else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeAdded}`}))
                }
            }
        )
    }

    const authorizedAddOrSetResource = (resource, parentId, tournamentId): Promise<R> => {
        let tournamentParam = "";
        if (tournamentId) {
            tournamentParam = `&tournament-id=${tournamentId}`
        }
        return post(`admin/${apiPostFix}?parent-id=${parentId}${tournamentParam}`, resource, apiToken).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.added} ${resourceType}`}))
                    return result.json().then()
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeAdded}`}))
                }
            }
        )
    }

    const deleteResource = (id, teamCode): Promise<boolean> => {
        return deleteFunction(`${apiPostFix}/${id}?team-code=${teamCode}`).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.deleted} ${resourceType}`}))
                    return true
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeDeleted}`}))
                }
            }
        )
    }

    const authorizedDeleteResource = (id, tournamentId): Promise<boolean> => {
        let tournamentParam = "";
        if (tournamentId) {
            tournamentParam = `?tournament-id=${tournamentId}`
        }
        return deleteFunction(`admin/${apiPostFix}/${id}${tournamentParam}`, apiToken).then(
            result => {
                if (result.status === 200) {
                    dispatch(setStatus({severity: "success", message: `${stringMap.deleted} ${resourceType}`}))
                    return true
                } else if (result.status === 401) {
                    handleUnauthorized(dispatch)
                } else {
                    dispatch(setStatus({severity: "error", message: `${resourceType} ${stringMap.couldNotBeDeleted}`}))
                }
            }
        )
    }

    const useGetResource = (id) => {
        return useQuery([resourceType, id], () => getResource(id), {enabled: !!id})
    }

    const useUpdateResource = () => {
        return useMutation(
            (obj: UpdateMutation<R>) => updateResource(obj.resource, obj.id, obj.teamCode),
            {
                onMutate: async ({resource, id, teamCode}) => {
                    await queryClient.cancelQueries([resourceType, id])

                    const previousResource = queryClient.getQueryData([resourceType, id])

                    if (resource) {
                        queryClient.setQueryData([resourceType, id], resource)
                    }
                    return { previousResource, resource }
                },
                onError: (err, res, context) => {
                    if (context?.previousResource) {
                        queryClient.setQueryData([resourceType, res.id], context.previousResource)
                    }
                },
                onSettled: (data, error, variables, context) => {
                    if (data) {
                        queryClient.setQueryData([resourceType, variables.id], data)
                        queryClient.invalidateQueries([variables.parentId, resourceType]).then()
                        if (resourceType === ResourceType.team) {
                            queryClient.invalidateQueries(["ALL_WITH_CODE", variables.parentId, resourceType]).then()
                            queryClient.invalidateQueries(["CODE", variables.parentId, resourceType]).then()
                        }
                        if (resourceType === ResourceType.tournament) {
                            queryClient.invalidateQueries(resourceType).then()
                        }
                    }
                }
            }
        )
    }

    const useAuthorizedUpdateResource = () => {
        return useMutation(
            (obj: AuthUpdateMutation<R>) => authorizedUpdateResource(obj.resource, obj.id, obj.tournamentId),
            {
                onMutate: async ({resource, id}) => {
                    await queryClient.cancelQueries([resourceType, id])

                    const previousResource = queryClient.getQueryData([resourceType, id])

                    if (resource) {
                        queryClient.setQueryData([resourceType, id], resource)
                    }
                    return { previousResource, resource }
                },
                onError: (err, res, context) => {
                    if (context?.previousResource) {
                        queryClient.setQueryData([resourceType, res.id], context.previousResource)
                    }
                },
                onSettled: (data, error, variables, context) => {
                    if (data) {
                        queryClient.setQueryData([resourceType, variables.id], data)
                        queryClient.invalidateQueries([variables.parentId, resourceType]).then()
                        if (resourceType === ResourceType.team) {
                            queryClient.invalidateQueries(["ALL_WITH_CODE", variables.parentId, resourceType]).then()
                            queryClient.invalidateQueries(["CODE", variables.parentId, resourceType]).then()
                        }
                        if (resourceType === ResourceType.tournament) {
                            queryClient.invalidateQueries(resourceType).then()
                        }
                    }
                }
            }
        )
    }

    const useDeleteResource = () => {
        return useMutation(
            (obj: DeleteMutation) => deleteResource(obj.id, obj.teamCode),
            {
                onSettled: (data, error, variables, context) => {
                    queryClient.invalidateQueries([variables.parentId, resourceType]).then()
                    if (resourceType == ResourceType.team) {
                        queryClient.invalidateQueries(["ALL_WITH_CODE", variables.parentId, resourceType]).then()
                        queryClient.invalidateQueries(["CODE", variables.parentId, resourceType]).then()
                    }
                    if (resourceType === ResourceType.tournament) {
                        queryClient.invalidateQueries(resourceType).then()
                    }
                }
            }
        )
    }

    const useAuthorizedDeleteResource = () => {
        return useMutation(
            (obj: AuthDeleteMutation) => authorizedDeleteResource(obj.id, obj.tournamentId),
            {
                onSettled: (data, error, variables, context) => {
                    queryClient.invalidateQueries([variables.parentId, resourceType]).then()
                    if (resourceType === ResourceType.team) {
                        queryClient.invalidateQueries(["ALL_WITH_CODE", variables.parentId, resourceType]).then()
                        queryClient.invalidateQueries(["CODE", variables.parentId, resourceType]).then()
                    }
                    if (resourceType === ResourceType.tournament) {
                        queryClient.invalidateQueries(resourceType).then()
                    }
                }
            }
        )
    }

    return {
        useGetResource,
        useUpdateResource,
        useAuthorizedUpdateResource,
        useDeleteResource,
        useAuthorizedDeleteResource,
        stringMap,
        dispatch,
        apiToken,
        addOrSetResource,
        addOrSetResourceWithRelation,
        authorizedAddOrSetResource,
        authorizedAddOrSetResourceWithRelation,
        getAll,
        getAllWithRelation,
        getSingle,
        getSingleWithRelation
    }

}
