import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {TournamentAdapter, TournamentResource} from "../../../resources/TournamentResource";
import {useSelector} from "react-redux";
import {useState} from "react";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import {selectLanguage} from "../../../util/UtilFunctions";

const EditTournamentDialog: React.FunctionComponent<{
    tournament: TournamentResource,
    userId: string,
    open: boolean,
    handleClose: () => void
}> = ({tournament, userId, open, handleClose}) => {
    const tournamentAdapter = new TournamentAdapter()
    const updateTournament = tournamentAdapter.actions().useAuthorizedUpdateResource()
    const stringMap = useSelector(selectLanguage)

    const [tournamentState, setTournamentState] = useState<TournamentResource>(tournament)

    const handleSubmit = (event) => {
        event.preventDefault()
        handleClose()
        if (tournamentState !== tournament) {
            updateTournament.mutate({
                resource: tournamentState,
                id: tournament.id,
                tournamentId: tournament.id,
                parentId: userId
            })
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{stringMap.editTournamentNameAndDate}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    size={"small"}
                    label={stringMap.name}
                    fullWidth
                    variant="outlined"
                    value={tournamentState.name}
                    InputLabelProps={{shrink: true}}
                    onChange={(event) => {
                        const newState = {...tournamentState}
                        newState.name = event.target.value
                        setTournamentState(newState)
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControlLabel
                            onChange={(event, checked) => {
                                const newState = {...tournamentState}
                                newState.rateSelf = checked
                                setTournamentState(newState)
                            }}
                            style={{paddingTop: 10}}
                            control={<Checkbox color={"secondary"} checked={tournamentState.rateSelf} />}
                            label={stringMap.rateSelves}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            size={"small"}
                            label={stringMap.maxRatingsPerTeam}
                            type={"number"}
                            fullWidth
                            variant="outlined"
                            value={tournamentState.maxRatingPerTeam}
                            onChange={(event) => {
                                const newState = {...tournamentState}
                                newState.maxRatingPerTeam = Number(event.target.value)
                                setTournamentState(newState)
                            }}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{stringMap.cancel}</Button>
                <Button onClick={handleSubmit}>{stringMap.save}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditTournamentDialog
