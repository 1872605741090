import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#84D0D2",
        },
        secondary: {
            main: "#193B5D"
        },
    },
    // typography: {
    //     fontFamily: `'Bowlby One', sans`
    // }
});
