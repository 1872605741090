import * as React from "react";
import {useSelector} from "react-redux";
import {useState} from "react";
import {
    Divider,
    Grid,
    IconButton, InputBase,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableCell from "@mui/material/TableCell";
import {TeamAdapter} from "../../../resources/TeamResource";
import EditableTeamTableRow from "../team-components/EditableTeamTableRow";
import {TournamentAdapter} from "../../../resources/TournamentResource";
import EditableTeamTableRowMobile from "../team-components/EditableTeamTableRowMobile";
import { BrowserView, MobileView } from 'react-device-detect';
import {selectLanguage} from "../../../util/UtilFunctions";


const AdminTournamentContent: React.FunctionComponent<{tournamentId: string}> = ({tournamentId}) => {
    const teamAdapter = new TeamAdapter()
    const tournamentAdapter = new TournamentAdapter()
    const teams = teamAdapter.actions().useGetAllWithCode(tournamentId)
    const scores = tournamentAdapter.actions().useGetScores(tournamentId)
    const addTeam = teamAdapter.actions().useAuthorizedAddResource()
    const stringMap = useSelector(selectLanguage)

    const [newTeamName, setNewTeamName] = useState("")

    return (
        <div style={{height: "100%", width: "100%"}}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <div style={{display: "inline-flex", width: "100%"}}>
                        <div style={{paddingLeft: 6, flexGrow: 1, textAlign: "left", alignSelf: "flex-end", minWidth: 180 }}>
                            <Typography>{stringMap.teams}</Typography>
                        </div>
                        <Paper variant="outlined" sx={{ p: '0px 2px', display: 'flex', marginBottom: '4px'}} >
                            <form
                                onSubmit={event => {
                                    event.preventDefault()
                                    if (newTeamName !== "") {
                                        addTeam.mutate({
                                            resource: {
                                                name: newTeamName,
                                                code: Math.floor(1000 + Math.random() * 9000) + "",
                                                id: ""
                                            },
                                            parentId: tournamentId,
                                            tournamentId
                                        })
                                    }
                                }}
                            >
                                <div style={{display: "inline-flex"}}>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1, width: "30%" }}
                                        placeholder={stringMap.createTeam}
                                        onChange={event => setNewTeamName(event.target.value)}
                                    />
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <IconButton type="submit" color="primary" sx={{ p: '10px' }}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </form>
                        </Paper>
                    </div>
                    <MobileView>
                        {teams.data?.map((team) => (
                            <EditableTeamTableRowMobile
                                key={team.id}
                                team={team}
                                tournamentId={tournamentId}
                                score={scores.data?.find(value => value.teamId === team.id)}
                            />
                        ))}
                    </MobileView>
                    <BrowserView>
                        <TableContainer component={Paper}>
                            <Table size={"small"}>
                                <TableHead>
                                    <TableCell>{stringMap.name}</TableCell>
                                    <TableCell>{stringMap.code}</TableCell>
                                    <TableCell>{stringMap.averageScore}</TableCell>
                                    <TableCell/>
                                </TableHead>
                                <TableBody>
                                    {teams.data?.map((team) => (
                                        <EditableTeamTableRow
                                            key={team.id}
                                            team={team}
                                            tournamentId={tournamentId}
                                            score={scores.data?.find(value => value.teamId === team.id)}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrowserView>
                </Grid>
            </Grid>
        </div>
    )

}

export default AdminTournamentContent
