import {setAPIToken, setUser} from "../store/persisted/persistedActions";
import {setStatus} from "../store/ui/status/statusActions";
import {RootState} from "../store";
import {ENStringMap} from "./ENStringMap";
import {DEStringMap} from "./DEStringMap";

export function handleUnauthorized(dispatch) {
    localStorage.removeItem("code-https://accounts.google.com/o/oauth2/auth-922748672818-2dcf8981sgnl84hakpre5e7m6a9jmv4k.apps.googleusercontent.com-email profile openid")
    dispatch(setAPIToken(""))
    dispatch(setUser(null))
    dispatch(setStatus({severity: "error", message: `Unauthorized or Token Expired`}))
}

export function selectLanguage(state: RootState) {
    if (state.persisted.language === "DE") {
        return DEStringMap
    } else return ENStringMap
}
