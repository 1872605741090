import * as React from "react";
import {useParams} from "react-router-dom";
import {TournamentAdapter, TournamentStatus} from "../../resources/TournamentResource";
import FinalizedTournamentPage from "./FinalizedTournamentPage";
import PublishedTournamentPage from "./PublishedTournamentPage";

const TournamentPage: React.FunctionComponent = () => {
    const { tournamentId } = useParams()
    const tournamentAdapter = new TournamentAdapter()
    const tournament = tournamentAdapter.actions().useGetResource(tournamentId)

    return (tournament.data?.status === TournamentStatus.FINALIZED ?
        <FinalizedTournamentPage tournament={tournament.data} />
            : tournament.data ? <PublishedTournamentPage tournament={tournament.data} />
                : <div/>
    )
}

export default TournamentPage
