import './App.css';
import {Application} from "./ui/Application";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider} from "react-redux";
import {store, storePersister} from "./store";
import {PersistGate} from "redux-persist/integration/react";

function App() {
    const queryClient = new QueryClient()
    return (
    <div className="App">
        <Provider store={store}>
            <PersistGate loading={null} persistor={storePersister}>
                <QueryClientProvider client={queryClient}>
                    <Application/>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    </div>
  );
}

export default App;
