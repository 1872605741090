export const DEStringMap = {
    loggedIn: "Eingeloggt",
    loggedOut: "Abgemeldet",
    couldNotBeFound: "konnte nicht gefunden werden",
    couldNotUpdated: "konnte nicht aktualisiert werden",
    couldNotBeAdded: "konnte nicht hinzugefügt werden",
    couldNotBeDeleted: "konnte nicht gelöscht werden",
    tournamentsLabel: "Turniere",
    myTournamentsLabel: "Meine Turniere",
    logout: "Ausloggen",
    createTournament: "Turnier erstellen",
    createTeam: "Team erstellen",
    teams: "Teams",
    name: "Name",
    date: "Datum",
    code: "Code",
    teamCode: "Team Code",
    noDateSet: "Kein Datum festgelegt",
    maxRatingsPerTeam: "Wertungen pro Team",
    rateSelves: "Selbst Bewerten",
    editTournamentNameAndDate: "Turniername bearbeiten",
    save: "Speichern",
    delete: "Löschen",
    cancel: "Abbrechen",
    yourTeam: "Dein Team",
    searchTournaments: "Turniere Suchen",
    category: "kategorie",
    ruleKnowledge: "Regelkenntnis",
    avoidBodyContact: "Körperkontakt",
    fairMinded: "Fairplay",
    positiveAttitude: "Positive Einstellung",
    communication: "Kommunikation",
    comment: "Kommentar",
    spiritScore: "Spirit Ergebnis",
    status: "Status",
    private: "Privat",
    published: "Öffentlich",
    finalized: "Abgeschlossen",
    averageScore: "Ergebnis",
    ownAverageScore: "Eigenes Ergebnis",
    opponent: "Gegner",
    receivedScores: "Bewertungen Erhalten",
    ownScores: "Eigene Bewertungen",
    total: "Total",
    added: "Hinzugefügt",
    updated: "Aktualisiert",
    deleted: "Gelöscht",
    ruleKnowledgeDesc: "Haben sie die Regeln absichtlich misinterpretiert? Haben sie sich an die Fristen gehalten? Waren sie bereit die Regeln zu erklären und/oder sich erklären zu lassen?",
    bodyContactDesc: "Haben sie Fouls, Körperkontakt und gefährliche Spielweisen vermieden? Wurde das Spiel sicher gespielt?",
    fairMindednessDesc: "Haben sie sich für begangene Fouls entschuldigt? Haben sie ihre Mitspieler korrigiert, wenn es falsche oder unnötige Anrufe gab?",
    positiveAttitudeDesc: "Waren sie höflich? Habe sie mit angemessener Intensität unabhangig vom Spielstand gespielt? Haben sie während und nach dem Spiel einen insgesamt positiven Eindruck hinterlassen?",
    communicationDesc: "Haben sie respektvoll kommuniziert und zugehört? Haben sie sich bei Diskussionen an die Zeitvorgaben erhielten? Haben sie uns kennengelernt?"
}
