// Properties
// id, name, code, winds, loses, ties

import {Resource, ResourceType} from "./adapters/types";
import {MultiChildSingleRelationAdapterActions} from "./adapters/MultiChildSingleRelationAdapter";
import {get} from "./APIVersion1";
import {useQuery} from "react-query";
import {handleUnauthorized} from "../util/UtilFunctions";
import {setStatus} from "../store/ui/status/statusActions";

export interface TeamResource extends Resource {
    name: string
    code: string
}

export class TeamAdapter {
    actions() {
        const adapterActions =  MultiChildSingleRelationAdapterActions<TeamResource>("team", ResourceType.team)

        const getByCode = (parentId, teamCode): Promise<TeamResource> => {
            return get(`team/code/${teamCode}?parent-id=${parentId}`).then(
                result => {
                    if (result.status === 200) {
                        return result.json().then(value => {
                            return value
                        })
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    }
                }
            )
        }

        const useGetByCode = (tournamentId, teamCode) => {
            return useQuery(["CODE", tournamentId, ResourceType.team], () => getByCode(tournamentId, teamCode), {enabled: !!teamCode && !!tournamentId})
        }

        const getAllWithCode = (parentId): Promise<TeamResource[]> => {
            return get(`admin/team?parent-id=${parentId}`, adapterActions.apiToken).then(
                result => {
                    if (result.status === 200) {
                        return result.json().then(values => {
                            values.forEach(value => {
                                if (value.hasOwnProperty("date") && value.date !== null) {
                                    value.date = new Date(value.date)
                                }
                            })
                            return values
                        })
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    } else {
                        adapterActions.dispatch(setStatus({severity: "error", message: `${ResourceType.team} ${adapterActions.stringMap.couldNotBeFound}`}))
                    }
                }
            )
        }

        const useGetAllWithCode = (parentId) => {
            return useQuery(["ALL_WITH_CODE", parentId, ResourceType.team], () => getAllWithCode(parentId), {enabled: !!parentId})
        }

        return {
            useGetAllWithCode,
            useGetByCode,
            useGetAll: adapterActions.useGetAll,
            useAddResource: adapterActions.useAddResource,
            useAuthorizedAddResource: adapterActions.useAuthorizedAddResource,
            useGetResource: adapterActions.useGetResource,
            useUpdateResource: adapterActions.useUpdateResource,
            useAuthorizedUpdateResource: adapterActions.useAuthorizedUpdateResource,
            useDeleteResource: adapterActions.useDeleteResource,
            useAuthorizedDeleteResource: adapterActions.useAuthorizedDeleteResource
        }
    }
}
