import {useMutation, useQuery, useQueryClient} from "react-query";
import {ResourceAdapterActions} from "./ResourceAdapter";
import {AddMutation, AuthAddMutation, ResourceType} from "./types";

export function MultiChildSingleRelationAdapterActions<R>(apiPostFix: string, resourceType: ResourceType) {

    const queryClient = useQueryClient()

    const resourceActions = ResourceAdapterActions<R>(apiPostFix, resourceType)

    const useGetAll = (parentId) => {
        return useQuery([parentId, resourceType], () => resourceActions.getAll(parentId), {enabled: !!parentId})
    }

    const useAddResource = () => {
        return useMutation(
            (obj: AddMutation<R>) => resourceActions.addOrSetResource(obj.resource, obj.parentId, obj.teamCode),
            {
                onMutate: async ({resource, parentId}) => {
                    await queryClient.cancelQueries([parentId, resourceType])

                    const previousResource = queryClient.getQueryData([parentId, resourceType]) as R[]|undefined
                    if (previousResource) {
                        const newResource = [...previousResource, resource]
                        if (resource) {
                            queryClient.setQueryData([parentId, resourceType], newResource)
                        }
                        return {previousResource, newResource}
                    }
                },
                onError: (err, res, context) => {
                    if (context?.previousResource) {
                        queryClient.setQueryData([res.parentId, resourceType], context.previousResource)
                    }
                },
                onSettled: (data, error, variables, context) => {
                    queryClient.invalidateQueries([variables.parentId, resourceType]).then()
                    if (resourceType === ResourceType.team) {
                        queryClient.invalidateQueries(["ALL_WITH_CODE", variables.parentId, resourceType]).then()
                        queryClient.invalidateQueries(["CODE", variables.parentId, resourceType]).then()
                    }
                    if (resourceType === ResourceType.tournament) {
                        queryClient.invalidateQueries(resourceType).then()
                    }
                }
            }
        )
    }

    const useAuthorizedAddResource = () => {
        return useMutation(
            (obj: AuthAddMutation<R>) => resourceActions.authorizedAddOrSetResource(obj.resource, obj.parentId, obj.tournamentId),
            {
                onMutate: async ({resource, parentId}) => {
                    await queryClient.cancelQueries([parentId, resourceType])

                    const previousResource = queryClient.getQueryData([parentId, resourceType]) as R[]|undefined
                    if (previousResource) {
                        const newResource = [...previousResource, resource]
                        if (resource) {
                            queryClient.setQueryData([parentId, resourceType], newResource)
                        }
                        return {previousResource, newResource}
                    }
                },
                onError: (err, res, context) => {
                    if (context?.previousResource) {
                        queryClient.setQueryData([res.parentId, resourceType], context.previousResource)
                    }
                },
                onSettled: (data, error, variables, context) => {
                    queryClient.invalidateQueries([variables.parentId, resourceType]).then()
                    if (resourceType === ResourceType.team) {
                        queryClient.invalidateQueries(["ALL_WITH_CODE", variables.parentId, resourceType]).then()
                        queryClient.invalidateQueries(["CODE", variables.parentId, resourceType]).then()
                    }
                    if (resourceType === ResourceType.tournament) {
                        queryClient.invalidateQueries(resourceType).then()
                    }
                }
            }
        )
    }

    return {
        apiToken: resourceActions.apiToken,
        dispatch: resourceActions.dispatch,
        stringMap: resourceActions.stringMap,
        queryClient,
        useAddResource,
        useAuthorizedAddResource,
        useGetAll,
        useGetResource: resourceActions.useGetResource,
        useUpdateResource: resourceActions.useUpdateResource,
        useAuthorizedUpdateResource: resourceActions.useAuthorizedUpdateResource,
        useDeleteResource: resourceActions.useDeleteResource,
        useAuthorizedDeleteResource: resourceActions.useAuthorizedDeleteResource
    }
}
