// Properties
// id, email

import {Resource, ResourceType} from "./adapters/types";
import {useQuery} from "react-query";
import {get} from "./APIVersion1";
import {ResourceAdapterActions} from "./adapters/ResourceAdapter";
import {handleUnauthorized} from "../util/UtilFunctions";
import {setStatus} from "../store/ui/status/statusActions";

export interface UserResource extends Resource {
    email: string
}

export class UserAdapter {
    actions() {
        const adapterActions = ResourceAdapterActions<UserResource>("user", ResourceType.user)

        const getAll = (): Promise<UserResource[]> => {
            return get(`user`).then(
                result => {
                    if (result.status === 200) {
                        return result.json().then()
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    } else {
                        adapterActions.dispatch(setStatus({severity: "error", message: `${ResourceType.user} ${adapterActions.stringMap.couldNotBeFound}`}))
                    }
                }
            )
        }

        const getAuthorizedUser = (): Promise<UserResource> => {
            return get(`admin/user`, adapterActions.apiToken).then(
                result => {
                    if (result.status === 200) {
                        return result.json().then()
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    } else {
                        adapterActions.dispatch(setStatus({severity: "error", message: `${ResourceType.user} ${adapterActions.stringMap.couldNotBeFound}`}))
                    }
                }
            )
        }

        const useGetAll = () => {
            return useQuery([ResourceType.user], () => getAll())
        }

        const useGetAuthorizedUser = () => {
            return useQuery(["ADMIN", ResourceType.user], () => getAuthorizedUser())
        }

        return {
            useGetAll,
            useGetAuthorizedUser,
            useGetResource: adapterActions.useGetResource,
        }
    }
}
