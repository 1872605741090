import {Reducer} from "redux";
import {PersistedActions, PersistedActionTypes} from "./persistedActions";
import {UserResource} from "../../resources/UserResource";

export interface PersistedState {
    apiToken: string;
    user: UserResource|null;
    language: "EN"|"DE"
}

const defaultState: PersistedState = {
    apiToken: "",
    user: null,
    language: "EN"
}

export const persistedReducer: Reducer<PersistedState, PersistedActions> = (
    state = defaultState, action: PersistedActions
) => {
    switch (action.type) {
        case PersistedActionTypes.SET_API_TOKEN:
            return {
                ...state,
                apiToken: action.payload.apiToken
            }
        case PersistedActionTypes.SET_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case PersistedActionTypes.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload.language
            }
        default:
            return state
    }
}
