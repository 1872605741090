import * as React from "react";
import {useSelector} from "react-redux";
import {useState} from "react";
import {
    Grid,
    IconButton,
    TextField, Typography
} from "@mui/material";
import {TeamAdapter, TeamResource} from "../../../resources/TeamResource";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {SpiritScoreResult} from "../../../resources/TournamentResource";
import {selectLanguage} from "../../../util/UtilFunctions";

const EditableTeamTableRowMobile: React.FunctionComponent<{tournamentId: string, team: TeamResource, score: SpiritScoreResult|undefined}> = ({tournamentId, team, score}) => {
    const teamAdapter = new TeamAdapter()
    const updateTeam = teamAdapter.actions().useAuthorizedUpdateResource()
    const deleteTeam = teamAdapter.actions().useAuthorizedDeleteResource()
    const stringMap = useSelector(selectLanguage)

    const [teamState, setTeamState] = useState<TeamResource>(team)

    React.useEffect(() => {
        setTeamState(team)
    }, [team])

    const isChanged = teamState !== team

    const handleCancel = () => {
        setTeamState(team)
    }

    const handleDelete = () => {
        deleteTeam.mutate({
            id: team.id,
            tournamentId,
            parentId: tournamentId
        })
    }

    const handleUpdate = () => {
        updateTeam.mutate({
            id: team.id,
            tournamentId,
            resource: teamState,
            parentId: tournamentId
        })
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={6} style={{backgroundColor: "#193B5D"}}>
                <div style={{display: "flex", height: "100%"}}>
                    <Typography style={{color: "white", paddingLeft: 6, textAlign: "left", alignSelf: "center"}}>{stringMap.name}</Typography>
                </div>
            </Grid>
            <Grid item xs={6} style={{backgroundColor: "#193B5D", paddingRight: 2}}>
                <TextField
                    value={teamState.name}
                    onChange={event => {
                        const newState = {...teamState}
                        newState.name = event.target.value
                        setTeamState(newState)
                    }}
                    margin={"dense"}
                    size={"small"}
                    InputProps={{
                        sx: {backgroundColor: "white"}
                    }}
                    fullWidth
                    variant={"outlined"}
                />
            </Grid>
            <Grid item xs={6}>
                <div style={{display: "flex", height: "100%"}}>
                    <Typography style={{paddingLeft: 6, textAlign: "left", alignSelf: "center"}}>{stringMap.code}</Typography>
                </div>
            </Grid>
            <Grid item xs={6} style={{paddingRight: 2}}>
                <TextField
                    value={teamState.code}
                    InputProps={{
                        readOnly: true,
                        sx: {backgroundColor: "white"},
                        endAdornment: (
                            <IconButton
                                size={"small"}
                                onClick={() => {
                                    const newState = {...teamState}
                                    newState.code = Math.floor(1000 + Math.random() * 9000) + ""
                                    setTeamState(newState)
                                }}
                            >
                                <AutorenewIcon/>
                            </IconButton>
                        )
                    }}
                    disabled
                    margin={"dense"}
                    size={"small"}
                    fullWidth
                    variant={"outlined"}
                />
            </Grid>
            <Grid item xs={6}>
                <div style={{display: "flex", height: "100%"}}>
                    <Typography style={{paddingLeft: 6, textAlign: "left", alignSelf: "center"}}>{stringMap.averageScore}</Typography>
                </div>
            </Grid>
            <Grid item xs={4}>
                <Typography style={{textAlign: "left", alignSelf: "center"}}>{score?.averageScore ?? 0}</Typography>
            </Grid>
            { isChanged &&
                <Grid item xs={1}>
                    <IconButton style={{margin: 0, padding: 0}} size={"small"} onClick={handleUpdate}>
                        <SaveIcon/>
                    </IconButton>
                </Grid>
            }
            { isChanged &&
                <Grid item xs={1}>
                    <IconButton style={{margin: 0, padding: 0}} size={"small"} onClick={handleCancel} >
                        <CancelIcon/>
                    </IconButton>
                </Grid>
            }
            { !isChanged &&
                <Grid item xs={2}>
                    <IconButton style={{margin: 0, padding: 0}} size={"small"} onClick={handleDelete}>
                        <DeleteIcon/>
                    </IconButton>
                </Grid>
            }
        </Grid>
    )
}

export default EditableTeamTableRowMobile
