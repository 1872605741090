import * as React from "react";
import {useSelector} from "react-redux";
import {TournamentAdapter, TournamentStatus} from "../../../resources/TournamentResource";
import {
    Divider,
    Grid,
    IconButton,
    InputBase,
    Paper
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useState} from "react";
import TournamentAccordion from "../../resuable-components/tournament-components/TournamentAccordion";
import {UserAdapter} from "../../../resources/UserResource";
import {selectLanguage} from "../../../util/UtilFunctions";


const MyTournaments: React.FunctionComponent = () => {
    const userAdapter = new UserAdapter()
    const user = userAdapter.actions().useGetAuthorizedUser()
    const tournamentAdapter = new TournamentAdapter()
    const tournaments = tournamentAdapter.actions().useGetAllFromParent(user.data?.id)
    const addTournament = tournamentAdapter.actions().useAuthorizedAddResource()
    const stringMap = useSelector(selectLanguage)

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const [newTournamentName, setNewTournamentName] = useState("")

    if (!user) {
        return <div/>
    }

    return (
        <div style={{height: "100%"}}>
            <Grid container spacing={2} >
                <Grid item xs={12} style={{paddingRight: 2}}>
                    <div style={{display: "inline-flex", width: "100%"}}>
                        <div style={{flexGrow: 1}}>
                            <div style={{width: "100%"}}/>
                        </div>
                        <Paper variant="outlined" sx={{ p: '2px 4px', display: 'flex'}} >
                            <form
                                onSubmit={event => {
                                    event.preventDefault()
                                    if (newTournamentName !== "" && user) {
                                        addTournament.mutate({
                                            resource: {
                                                name: newTournamentName,
                                                date: null,
                                                id: "",
                                                maxRatingPerTeam: 1,
                                                rateSelf: false,
                                                status: TournamentStatus.PRIVATE
                                            },
                                            parentId: user.data?.id,
                                            tournamentId: undefined
                                        })
                                    }
                                }}
                            >
                                <div style={{display: "inline-flex"}}>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder={stringMap.createTournament}
                                        onChange={event => setNewTournamentName(event.target.value)}
                                    />
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <IconButton type="submit" color="primary" sx={{ p: '10px' }}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </form>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{height: "100%"}} >
                        {tournaments.data?.map((tournament, index) => (
                            <TournamentAccordion
                                key={tournament.id}
                                tournament={tournament}
                                index={index}
                                handleChange={handleChange}
                                expanded={expanded}
                            />
                        ))}
                    </div>
                </Grid>
            </Grid>
        </div>
    )

}

export default MyTournaments
