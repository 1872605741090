import {Store} from "redux";
import { PersistConfig } from 'redux-persist/es/types'
import { getStoredState, REHYDRATE } from 'redux-persist'

export default function crossBrowserListener(store: Store, persistConfig: PersistConfig<any>) {
    return async () => {
        const state = await getStoredState(persistConfig)
        store.dispatch({
            type: REHYDRATE,
            key: persistConfig.key,
            payload: state
        })
    }
}
