// Properties
// id, name, date

import {AuthStatusUpdate, Resource, ResourceType} from "./adapters/types";
import {MultiChildSingleRelationAdapterActions} from "./adapters/MultiChildSingleRelationAdapter";
import {get, patch} from "./APIVersion1";
import {useMutation, useQuery} from "react-query";
import {handleUnauthorized} from "../util/UtilFunctions";
import {setStatus} from "../store/ui/status/statusActions";

export enum TournamentStatus {
    PRIVATE = "PRIVATE",
    PUBLISHED = "PUBLISHED",
    FINALIZED = "FINALIZED"
}

export interface SpiritScoreResult {
    teamId: string,
    teamName: string,
    averageScore: number,
    ownAverageScore: number
}

export interface TournamentResource extends Resource {
    name: string
    date: Date | null,
    rateSelf: boolean,
    maxRatingPerTeam: number,
    status: TournamentStatus
}

export class TournamentAdapter {
    actions() {
        const adapterActions = MultiChildSingleRelationAdapterActions<TournamentResource>("tournament", ResourceType.tournament)

        const getAll = (status?: TournamentStatus): Promise<TournamentResource[]> => {
            let statusParam = ""
            if (status) {
                statusParam = `?status=${status}`
            }
            return get(`tournament${statusParam}`).then(
                result => {
                    if (result.status === 200) {
                        return result.json().then(values => {
                            values.forEach(value => {
                                if (value.hasOwnProperty("date") && value.date !== null) {
                                    value.date = new Date(value.date)
                                }
                            })
                            return values
                        })
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    } else {
                        adapterActions.dispatch(setStatus({severity: "error", message: `${ResourceType.tournament} ${adapterActions.stringMap.couldNotBeFound}`}))
                    }
                }
            )
        }

        const getScores = (id: string): Promise<SpiritScoreResult[]> => {
            return get(`tournament/${id}/scores`).then(
                result => {
                    if (result.status === 200) {
                        return result.json().then(values => {
                            return values
                        })
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    } else {
                        adapterActions.dispatch(setStatus({severity: "error", message: `${ResourceType.tournament} ${adapterActions.stringMap.couldNotBeFound}`}))
                    }
                }
            )
        }


        const useGetAll = (status?: TournamentStatus) => {
            return useQuery([ResourceType.tournament, status], () => getAll(status))
        }

        const useGetScores = (id) => {
            return useQuery([ResourceType.tournament, "SCORES", id], () => getScores(id), {enabled: !!id})
        }

        const authorizedChangeStatus = (id, status: TournamentStatus): Promise<TournamentResource> => {
            return patch(`admin/tournament/${id}/status?status=${status}`, "", adapterActions.apiToken).then(
                result => {
                    if (result.status === 200) {
                        adapterActions.dispatch(setStatus({severity: "success", message: `${adapterActions.stringMap.updated} ${ResourceType.tournament}`}))
                        return result.json().then()
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    } else {
                        adapterActions.dispatch(setStatus({severity: "error", message: `${ResourceType.tournament} ${adapterActions.stringMap.couldNotUpdated}`}))
                    }
                }
            )
        }

        const useAuthorizedChangeStatus = () => {
            return useMutation(
                (obj: AuthStatusUpdate) => authorizedChangeStatus(obj.id, obj.status),
                {
                    onSettled: (data, error, variables, context) => {
                        if (data) {
                            adapterActions.queryClient.setQueryData([ResourceType.tournament, variables.id], data)
                            adapterActions.queryClient.invalidateQueries(ResourceType.tournament).then()
                            adapterActions.queryClient.invalidateQueries([variables.parentId, ResourceType.tournament]).then()
                        }
                    }
                }
            )
        }

        return {
            useGetAll,
            useGetScores,
            useAuthorizedChangeStatus,
            useGetAllFromParent: adapterActions.useGetAll,
            useAddResource: adapterActions.useAddResource,
            useAuthorizedAddResource: adapterActions.useAuthorizedAddResource,
            useGetResource: adapterActions.useGetResource,
            useUpdateResource: adapterActions.useUpdateResource,
            useAuthorizedUpdateResource: adapterActions.useAuthorizedUpdateResource,
            useDeleteResource: adapterActions.useDeleteResource,
            useAuthorizedDeleteResource: adapterActions.useAuthorizedDeleteResource
        }
    }
}
