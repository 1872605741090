import storage from 'redux-persist/lib/storage'
import {PersistedActionTypes} from "./persisted/persistedActions";
import { persistReducer, persistStore } from "redux-persist";
import reduxThunk from "redux-thunk";
import {applyMiddleware, combineReducers, createStore, Store} from "redux";
import {createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import crossBrowserListener from "./crossBrowserListener";
import {createReduxHistoryContext} from "redux-first-history";
import {createHashHistory} from "history";
import {persistedReducer} from "./persisted/persistedReducer";
import {uiReducer} from "./ui/uiReducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["persisted"]
}

const actionWhiteList = [
    'persist/FLUSH',
    'persist/PAUSE',
    'persist/PURGE',
    'persist/REGISTER',
    ...Object.keys(PersistedActionTypes)
]

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createHashHistory(),
})

const rootReducer = combineReducers({
        router: routerReducer,
        persisted: persistedReducer,
        ui: uiReducer
    })

export const store: Store = createStore(
    persistReducer(persistConfig, rootReducer),
    applyMiddleware(
        reduxThunk,
        routerMiddleware,
        createStateSyncMiddleware({whitelist:actionWhiteList})
    )
)

initMessageListener(store)

export const storePersister = persistStore(store)
export const reduxHashHistory = createReduxHistory(store)

export type RootState = ReturnType<typeof rootReducer>

window.addEventListener("storage", crossBrowserListener(store, persistConfig))
