import {Action, ActionCreator} from "redux";
import {UserResource} from "../../resources/UserResource";

export enum PersistedActionTypes {
    SET_API_TOKEN = "SET_API_TOKEN",
    SET_USER = "SET_USER",
    SET_LANGUAGE = "SET_LANGUAGE"
}

export interface SetAPIToken extends Action {
    type: PersistedActionTypes.SET_API_TOKEN
    payload: {
        apiToken: string
    }
}

export interface SetUser extends Action {
    type: PersistedActionTypes.SET_USER
    payload: {
        user: UserResource|null
    }
}

export interface SetLanguage extends Action {
    type: PersistedActionTypes.SET_LANGUAGE
    payload: {
        language: "EN"|"DE"
    }
}

export type PersistedActions = SetAPIToken | SetUser | SetLanguage;

export const setAPIToken: ActionCreator<SetAPIToken> = (apiToken: string) => {
    return {
        type: PersistedActionTypes.SET_API_TOKEN,
        payload: {
            apiToken
        }
    }
}

export const setUser: ActionCreator<SetUser> = (user: UserResource|null) => {
    return {
        type: PersistedActionTypes.SET_USER,
        payload: {
            user
        }
    }
}

export const setLanguage: ActionCreator<SetLanguage> = (language: "EN"|"DE") => {
    return {
        type: PersistedActionTypes.SET_LANGUAGE,
        payload: {
            language
        }
    }
}
