import {Reducer} from "redux";
import {PageDetailsActions, PageDetailsActionTypes} from "./pageDetailsActions";

export interface PageDetailsState {
    tournamentId: string;
}

const defaultState: PageDetailsState = {
    tournamentId: ""
}

export const pageDetailsReducer: Reducer<PageDetailsState, PageDetailsActions> = (
    state = defaultState, action: PageDetailsActions
) => {
    switch (action.type) {
        case PageDetailsActionTypes.SET_PAGE_DETAILS:
            return {
                ...action.payload.state
            }
        default:
            return state
    }
}
