export const API_ROOT = 'https://api.ultispirit.com:8080/api/v1'

export const get = (url: string, auth?: string) => {
    if (auth) {
        return fetch(`${API_ROOT}/${url}`, {
            headers: {
                Accept: 'application/json',
                Authorization: auth,
            }
        })
    }
    return fetch(`${API_ROOT}/${url}`, {
        headers: {Accept: 'application/json'},
    })
}

export const post = (url: string, body: any, auth?: string) => {
    if (auth) {
        return fetch(`${API_ROOT}/${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Host: 'api.ultispirit.com:8080',
                Origin: "api.ultispirit.com:8080",
                Authorization: auth
            },
            body: JSON.stringify(body),
        })
    }
    return fetch(`${API_ROOT}/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Host: 'api.ultispirit.com:8080',
            Origin: "api.ultispirit.com:8080"
        },
        body: JSON.stringify(body),
    })
}

export const patch = (url: string, body: any, auth?: string) => {
    if (auth) {
        return fetch(`${API_ROOT}/${url}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Host: 'api.ultispirit.com:8080',
                Origin: "api.ultispirit.com:8080",
                Authorization: auth
            },
            body: JSON.stringify(body),
        })
    }
    return fetch(`${API_ROOT}/${url}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Host: 'api.ultispirit.com:8080',
            Origin: "api.ultispirit.com:8080"
        },
        body: JSON.stringify(body),
    })
}

export const deleteFunction = (url: string, auth?: string) => {
    if (auth) {
        return fetch(`${API_ROOT}/${url}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: auth
            },
        })
    }
    return fetch(`${API_ROOT}/${url}`, {
        method: 'DELETE',
        headers: {Accept: 'application/json'},
    })
}
