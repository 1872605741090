import * as React from "react";
import {SpiritScoreAdapter, SpiritScoreResource} from "../../../resources/SpiritScoreResource";
import {FormEvent, useState} from "react";
import {Grid, IconButton, MenuItem, Paper, Popover, Select, Typography} from "@mui/material";
import {TeamResource} from "../../../resources/TeamResource";
import {useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import HelpIcon from '@mui/icons-material/Help';
import {isMobile} from "react-device-detect";
import {selectLanguage} from "../../../util/UtilFunctions";
import {TournamentAdapter} from "../../../resources/TournamentResource";
import {UserAdapter} from "../../../resources/UserResource";

const SpiritScoreComponent: React.FunctionComponent<{
    teamCode: string,
    tournamentId: string,
    teamId: string,
    teamName: string,
    opponentTeam: TeamResource,
    spiritScoreResource: SpiritScoreResource | undefined,
    rateSelves: boolean,
    handleClose: () => void
}> = ({teamCode, tournamentId, teamName, teamId, opponentTeam, spiritScoreResource, rateSelves, handleClose}) => {
    const spiritScoreAdapter = new SpiritScoreAdapter()
    const tournamentAdapter = new TournamentAdapter()
    const userAdapter = new UserAdapter()
    const user = userAdapter.actions().useGetAuthorizedUser()
    const updateSpiritScore = spiritScoreAdapter.actions().useUpdateResource()
    const addSpiritScore = spiritScoreAdapter.actions().useAddResource()
    const deleteSpiritScore = spiritScoreAdapter.actions().useAuthorizedDeleteResource()
    const adminTournaments = tournamentAdapter.actions().useGetAllFromParent(user.data?.id)

    const [ruleKnowledgeAnchorEl, setRuleKnowledgeAnchorEl] = React.useState<HTMLElement | null>(null);
    const [positiveAttitudeAnchorEl, setPositiveAttitudeAnchorEl] = React.useState<HTMLElement | null>(null);
    const [communicationAnchorEl, setCommunicationAnchorEl] = React.useState<HTMLElement | null>(null);
    const [fairMindedAnchorEl, setFairMindedAnchorEl] = React.useState<HTMLElement | null>(null);
    const [bodyContactAnchorEl, setBodyContactAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleRuleKnowledgePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        handleDescClose()
        setRuleKnowledgeAnchorEl(event.currentTarget);
    };
    const handlePositiveAttitudePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        handleDescClose()
        setPositiveAttitudeAnchorEl(event.currentTarget);
    };
    const handleCommunicationPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        handleDescClose()
        setCommunicationAnchorEl(event.currentTarget);
    };
    const handleFairMindedPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        handleDescClose()
        setFairMindedAnchorEl(event.currentTarget);
    };
    const handleBodyContactPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        handleDescClose()
        setBodyContactAnchorEl(event.currentTarget);
    };

    const handleDescClose = () => {
        setRuleKnowledgeAnchorEl(null);
        setPositiveAttitudeAnchorEl(null);
        setCommunicationAnchorEl(null);
        setFairMindedAnchorEl(null);
        setBodyContactAnchorEl(null);
    };

    const stringMap = useSelector(selectLanguage)

    const [spiritScoreState, setSpiritScoreState] = useState<SpiritScoreResource>(
        spiritScoreResource ?? {
            id: "",
            avoidBodyContact: 2,
            communication: 2,
            fairMinded: 2,
            opponentTeam: opponentTeam.id,
            opponentTeamName: opponentTeam.name,
            ratingTeamName: teamName,
            positiveAttitude: 2,
            ruleKnowledge: 2,
            ownAvoidBodyContact: 2,
            ownCommunication: 2,
            ownFairMinded: 2,
            ownPositiveAttitude: 2,
            ownRuleKnowledge: 2,
            comment: ""
        })

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault()
        if (spiritScoreState.id !== "") {
            updateSpiritScore.mutate({
                id: spiritScoreState.id,
                parentId: teamId,
                resource: spiritScoreState,
                teamCode: teamCode,
            })
        } else {
            addSpiritScore.mutate({
                parentId: teamId,
                resource: spiritScoreState,
                teamCode: teamCode
            })
        }
        handleClose()
    }

    const handleDelete = () => {
        if (spiritScoreState.id !== "") {
            deleteSpiritScore.mutate({
                id: spiritScoreState.id,
                tournamentId: tournamentId,
                parentId: teamId
            })
        }
        handleClose()
    }

    const getRuleKnowledgeField = () => {
        return (
            <Select
                value={spiritScoreState.ruleKnowledge}
                size={"small"}
                sx={{ width: 60, backgroundColor: "white" }}
                onChange={(event) => {
                    const newState = {...spiritScoreState}
                    newState.ruleKnowledge = event.target.value as number
                    setSpiritScoreState(newState)
                }}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
            </Select>
        )
    }

    const getAvoidBodyContactField = () => {
        return (
            <Select
                value={spiritScoreState.avoidBodyContact}
                size={"small"}
                sx={{ width: 60, backgroundColor: "white" }}
                onChange={(event) => {
                    const newState = {...spiritScoreState}
                    newState.avoidBodyContact = event.target.value as number
                    setSpiritScoreState(newState)
                }}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
            </Select>
        )
    }

    const getFairMindedField = () => {
        return (
            <Select
                value={spiritScoreState.fairMinded}
                size={"small"}
                sx={{ width: 60, backgroundColor: "white" }}
                onChange={(event) => {
                    const newState = {...spiritScoreState}
                    newState.fairMinded = event.target.value as number
                    setSpiritScoreState(newState)
                }}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
            </Select>
        )
    }

    const getPositiveAttitudeField = () => {
        return (
            <Select
                value={spiritScoreState.positiveAttitude}
                size={"small"}
                sx={{ width: 60, backgroundColor: "white" }}
                onChange={(event) => {
                    const newState = {...spiritScoreState}
                    newState.positiveAttitude = event.target.value as number
                    setSpiritScoreState(newState)
                }}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
            </Select>
        )
    }

    const getCommunicationField = () => {
        return (
            <Select
                value={spiritScoreState.communication}
                size={"small"}
                sx={{ width: 60, backgroundColor: "white" }}
                onChange={(event) => {
                    const newState = {...spiritScoreState}
                    newState.communication = event.target.value as number
                    setSpiritScoreState(newState)
                }}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
            </Select>
        )
    }

    const getCommentField = () => {
        return (
            <div>
                <Typography color={"primary"} variant={"caption"}>{stringMap.comment}</Typography>
                <TextField
                    multiline
                    rows={3}
                    fullWidth
                    value={spiritScoreState.comment}
                    onChange={event => {
                        const newState = {...spiritScoreState}
                        newState.comment = event.target.value
                        setSpiritScoreState(newState)
                    }}
                    InputProps={{
                        sx: {backgroundColor: "white"}
                    }}
                    variant="outlined"
                />
            </div>
        )
    }

    const ruleKnowledgeLabel = (marginLeft?: number) => {
        return (
            <div style={{display: "inline-flex"}}>
                <Typography style={{marginLeft: marginLeft, fontSize: isMobile && !marginLeft ? "0.8rem" : "1rem"}}>
                    {stringMap.ruleKnowledge}
                </Typography>
                <IconButton style={{padding: 0, paddingLeft: 2}} size={"small"} onClick={ruleKnowledgeAnchorEl ? handleDescClose : handleRuleKnowledgePopoverOpen}>
                    <HelpIcon style={{fontSize: "1.25rem"}}/>
                </IconButton>
                <Popover
                    sx={{pointerEvents: 'none',}}
                    open={Boolean(ruleKnowledgeAnchorEl)}
                    anchorEl={ruleKnowledgeAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleDescClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{stringMap.ruleKnowledgeDesc}</Typography>
                </Popover>
            </div>
        )
    }

    const positiveAttitudeLabel = (marginLeft?: number) => {
        return (
            <div style={{display: "inline-flex"}}>
                <Typography style={{marginLeft: marginLeft, fontSize: isMobile && !marginLeft ? "0.8rem" : "1rem"}}>
                    {stringMap.positiveAttitude}
                </Typography>
                <IconButton style={{padding: 0, paddingLeft: 2}} size={"small"} onClick={positiveAttitudeAnchorEl ? handleDescClose : handlePositiveAttitudePopoverOpen}>
                    <HelpIcon style={{fontSize: "1.25rem"}}/>
                </IconButton>
                <Popover
                    sx={{pointerEvents: 'none',}}
                    open={Boolean(positiveAttitudeAnchorEl)}
                    anchorEl={positiveAttitudeAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleDescClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{stringMap.positiveAttitudeDesc}</Typography>
                </Popover>
            </div>
        )
    }

    const bodyContactLabel = (marginLeft?: number) => {
        return (
            <div style={{display: "inline-flex"}}>
                <Typography style={{marginLeft: marginLeft, fontSize: isMobile && !marginLeft ? "0.8rem" : "1rem"}}>
                    {stringMap.avoidBodyContact}
                </Typography>
                <IconButton style={{padding: 0, paddingLeft: 2}} size={"small"} onClick={bodyContactAnchorEl ? handleDescClose : handleBodyContactPopoverOpen}>
                    <HelpIcon style={{fontSize: "1.25rem"}}/>
                </IconButton>
                <Popover
                    sx={{pointerEvents: 'none',}}
                    open={Boolean(bodyContactAnchorEl)}
                    anchorEl={bodyContactAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleDescClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{stringMap.bodyContactDesc}</Typography>
                </Popover>
            </div>
        )
    }

    const fairMindedLabel = (marginLeft?: number) => {
        return (
            <div style={{display: "inline-flex"}}>
                <Typography style={{marginLeft: marginLeft, fontSize: isMobile && !marginLeft ? "0.8rem" : "1rem"}}>
                    {stringMap.fairMinded}
                </Typography>
                <IconButton style={{padding: 0, paddingLeft: 2}} size={"small"} onClick={fairMindedAnchorEl ? handleDescClose : handleFairMindedPopoverOpen}>
                    <HelpIcon style={{fontSize: "1.25rem"}}/>
                </IconButton>
                <Popover
                    sx={{pointerEvents: 'none',}}
                    open={Boolean(fairMindedAnchorEl)}
                    anchorEl={fairMindedAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleDescClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{stringMap.fairMindednessDesc}</Typography>
                </Popover>
            </div>
        )
    }

    const communicationLabel = (marginLeft?: number) => {
        return (
            <div style={{display: "inline-flex"}}>
                <Typography style={{marginLeft: marginLeft, fontSize: isMobile && !marginLeft ? "0.8rem" : "1rem"}}>
                    {stringMap.communication}
                </Typography>
                <IconButton style={{padding: 0, paddingLeft: 2}} size={"small"} onClick={communicationAnchorEl ? handleDescClose : handleCommunicationPopoverOpen}>
                    <HelpIcon style={{fontSize: "1.25rem"}}/>
                </IconButton>
                <Popover
                    sx={{pointerEvents: 'none',}}
                    open={Boolean(communicationAnchorEl)}
                    anchorEl={communicationAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleDescClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{stringMap.communicationDesc}</Typography>
                </Popover>
            </div>
        )
    }

    if (rateSelves) {
        return (
            <div style={{height: "100%", backgroundColor: "#193B5D"}}>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <div style={{width: "100%", display: "inline-flex", justifyContent: "space-between"}}>
                                <Typography color={"primary"} >{opponentTeam.name}</Typography>
                                <Typography color={"primary"} >{stringMap.yourTeam}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                                {getRuleKnowledgeField()}
                                {ruleKnowledgeLabel()}
                                <Select
                                    value={spiritScoreState.ownRuleKnowledge}
                                    size={"small"}
                                    sx={{ width: 60, backgroundColor: "white" }}
                                    onChange={(event) => {
                                        const newState = {...spiritScoreState}
                                        newState.ownRuleKnowledge = event.target.value as number
                                        setSpiritScoreState(newState)
                                    }}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                                {getAvoidBodyContactField()}
                                {bodyContactLabel()}
                                <Select
                                    value={spiritScoreState.ownAvoidBodyContact}
                                    size={"small"}
                                    sx={{ width: 60, backgroundColor: "white" }}
                                    onChange={(event) => {
                                        const newState = {...spiritScoreState}
                                        newState.ownAvoidBodyContact = event.target.value as number
                                        setSpiritScoreState(newState)
                                    }}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                                {getFairMindedField()}
                                {fairMindedLabel()}
                                <Select
                                    value={spiritScoreState.ownFairMinded}
                                    size={"small"}
                                    sx={{ width: 60, backgroundColor: "white" }}
                                    onChange={(event) => {
                                        const newState = {...spiritScoreState}
                                        newState.ownFairMinded = event.target.value as number
                                        setSpiritScoreState(newState)
                                    }}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper square={true} sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                                {getPositiveAttitudeField()}
                                {positiveAttitudeLabel()}
                                <Select
                                    value={spiritScoreState.ownPositiveAttitude}
                                    size={"small"}
                                    sx={{ width: 60, backgroundColor: "white" }}
                                    onChange={(event) => {
                                        const newState = {...spiritScoreState}
                                        newState.ownPositiveAttitude = event.target.value as number
                                        setSpiritScoreState(newState)
                                    }}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                                {getCommunicationField()}
                                {communicationLabel()}
                                <Select
                                    value={spiritScoreState.ownCommunication}
                                    size={"small"}
                                    sx={{ width: 60, backgroundColor: "white" }}
                                    onChange={(event) => {
                                        const newState = {...spiritScoreState}
                                        newState.ownCommunication = event.target.value as number
                                        setSpiritScoreState(newState)
                                    }}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            {getCommentField()}
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{display: "inline-flex", width: "100%", marginTop: 2}}>
                                <div style={{flexGrow: 1}} />
                                { spiritScoreState.id !== "" && adminTournaments.data && adminTournaments.data.find(value => value.id === tournamentId) !== undefined && (
                                    <Button variant={"outlined"} onClick={handleDelete}>{stringMap.delete}</Button>
                                )}
                                <Button variant={"contained"} type={"submit"}>{stringMap.save}</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }

    return (
        <div style={{height: "100%", backgroundColor: "#193B5D"}}>
            <form onSubmit={handleSubmit}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <div style={{display: "inline-flex"}}>
                            <Typography color={"primary"} >{opponentTeam.name}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper square sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                            {ruleKnowledgeLabel(5)}
                            {getRuleKnowledgeField()}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper square sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                            {bodyContactLabel(5)}
                            {getAvoidBodyContactField()}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper square sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                            {fairMindedLabel(5)}
                            {getFairMindedField()}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper square sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                            {positiveAttitudeLabel(5)}
                            {getPositiveAttitudeField()}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper square sx={{width: "100%", alignItems: "center", justifyContent: "space-between" ,backgroundColor: "#84D0D2", display: "inline-flex"}}>
                            {communicationLabel(5)}
                            {getCommunicationField()}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {getCommentField()}
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{display: "inline-flex", width: "100%", marginTop: 2}}>
                            <div style={{flexGrow: 1}} />
                            { spiritScoreState.id !== "" && adminTournaments.data && adminTournaments.data.find(value => value.id === tournamentId) !== undefined && (
                                <Button variant={"outlined"} onClick={handleDelete}>{stringMap.delete}</Button>
                            )}
                            <Button variant={"contained"} type={"submit"}>{stringMap.save}</Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default SpiritScoreComponent
