import * as React from "react";
import {
    SpiritScoreResult,
} from "../../../resources/TournamentResource";
import {useSelector} from "react-redux";
import {CustomAccordion, CustomAccordionDetails, CustomAccordionSummary} from "../CustomAccordian";
import {
    Typography
} from "@mui/material";
import {TeamResource} from "../../../resources/TeamResource";
import {SpiritScoreAdapter} from "../../../resources/SpiritScoreResource";
import TeamScoreOverviewTable from "./TeamScoreOverviewTable";
import {selectLanguage} from "../../../util/UtilFunctions";

const FinalizedTeamAccordion: React.FunctionComponent<{
    team: TeamResource,
    score: SpiritScoreResult,
    selfRated: boolean
    expanded: string | false,
    index: number,
    handleChange:  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void
}> = ({team, score, selfRated, expanded, index, handleChange}) => {
    const spiritScoreAdapter = new SpiritScoreAdapter()
    const stringMap = useSelector(selectLanguage)

    const teamScores = spiritScoreAdapter.actions().useGetAllByOpponentTeam(team.id)
    const selfRatedTeamScores = spiritScoreAdapter.actions().useGetAll(team.id)

    const getScoreText = () => {
        if (selfRated) {
            return (
                <CustomAccordionSummary>
                    <Typography noWrap sx={{ width: "40%", flexShrink: 0, textAlign: "left" }}>{index+1}. {team.name}</Typography>
                    <Typography noWrap sx={{ width: "30%", flexShrink: 0, textAlign: "left" }}>{stringMap.averageScore} : {score.averageScore}</Typography>
                    <Typography noWrap sx={{ width: "30%", flexShrink: 0, textAlign: "left" }}>{stringMap.ownAverageScore} : {score.ownAverageScore}</Typography>
                </CustomAccordionSummary>
            )
        } else {
            return (
                <CustomAccordionSummary>
                    <Typography noWrap sx={{ width: "40%", flexShrink: 0, textAlign: "left" }}>{index+1}. {team.name}</Typography>
                    <Typography noWrap sx={{ width: "60%", flexShrink: 0, textAlign: "left" }}>{stringMap.averageScore} : {score.averageScore}</Typography>
                </CustomAccordionSummary>
            )
        }
    }

    return (
        <div>
            <CustomAccordion
                key={team.id}
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
            >
                {getScoreText()}
                <CustomAccordionDetails>
                    <div style={{width: "100%", textAlign: "left"}}>
                        <Typography sx={{ color: 'text.secondary', textAlign: "left" }}>
                            {stringMap.receivedScores}
                        </Typography>
                        <TeamScoreOverviewTable spiritScores={teamScores.data} ownRatings={false}/>
                    </div>
                    { selfRated &&
                        <div style={{width: "100%"}}>
                            <Typography sx={{ color: 'text.secondary', textAlign: "left" }}>
                                {stringMap.ownScores}
                            </Typography>
                            <TeamScoreOverviewTable spiritScores={selfRatedTeamScores.data} ownRatings={true}/>
                        </div>
                    }
                </CustomAccordionDetails>
            </CustomAccordion>
        </div>
    )
}

export default FinalizedTeamAccordion
