import * as React from "react";
import {useState} from "react";
import {
    Grid,
    IconButton,
    TableRow, TextField, Typography
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {TeamAdapter, TeamResource} from "../../../resources/TeamResource";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {SpiritScoreResult} from "../../../resources/TournamentResource";
import {selectLanguage} from "../../../util/UtilFunctions";

const EditableTeamTableRow: React.FunctionComponent<{tournamentId: string, team: TeamResource, score: SpiritScoreResult|undefined}> = ({tournamentId, team, score}) => {
    const teamAdapter = new TeamAdapter()
    const updateTeam = teamAdapter.actions().useAuthorizedUpdateResource()
    const deleteTeam = teamAdapter.actions().useAuthorizedDeleteResource()

    const [teamState, setTeamState] = useState<TeamResource>(team)

    React.useEffect(() => {
        setTeamState(team)
    }, [team])

    const isChanged = teamState !== team

    const handleCancel = () => {
        setTeamState(team)
    }

    const handleDelete = () => {
        deleteTeam.mutate({
            id: team.id,
            tournamentId,
            parentId: tournamentId
        })
    }

    const handleUpdate = () => {
        updateTeam.mutate({
            id: team.id,
            tournamentId,
            resource: teamState,
            parentId: tournamentId
        })
    }

    return (
        <TableRow key={team.id}>
            <TableCell>
                <TextField
                    value={teamState.name}
                    onChange={event => {
                        const newState = {...teamState}
                        newState.name = event.target.value
                        setTeamState(newState)
                    }}
                    margin={"dense"}
                    size={"small"}
                    fullWidth
                    variant={"outlined"}
                />
            </TableCell>
            <TableCell>
                <TextField
                    value={teamState.code}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton
                                size={"small"}
                                onClick={() => {
                                    const newState = {...teamState}
                                    newState.code = Math.floor(1000 + Math.random() * 9000) + ""
                                    setTeamState(newState)
                                }}
                            >
                                <AutorenewIcon/>
                            </IconButton>
                        )
                    }}
                    disabled
                    margin={"dense"}
                    size={"small"}
                    fullWidth
                    variant={"outlined"}
                />
            </TableCell>
            <TableCell>
                <Typography>
                    {score?.averageScore ?? 0}
                </Typography>
            </TableCell>
            <TableCell align="right">
                {isChanged ? (
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <IconButton style={{margin: 0, padding: 0}} size={"small"} onClick={handleUpdate}>
                                <SaveIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton style={{margin: 0, padding: 0}} size={"small"} onClick={handleCancel} >
                                <CancelIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <IconButton style={{margin: 0, padding: 0}} size={"small"} onClick={handleDelete}>
                                <DeleteIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
            </TableCell>
        </TableRow>
    )

}

export default EditableTeamTableRow
