import * as React from "react";
import {Alert, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {setStatus} from "../store/ui/status/statusActions";

const StatusGrowl: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const message = useSelector((state: RootState) => state.ui.status.message)
    const severity = useSelector((state: RootState) => state.ui.status.severity)

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setStatus({severity: "success", message: null}))
    };

    return (
        <Snackbar open={message !== null} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default StatusGrowl
