import * as React from "react";
import {
    AppBar,
    Box,
    Divider,
    IconButton,
    Menu,
    MenuItem, ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import GoogleIcon from '@mui/icons-material/Google';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {setAPIToken, setLanguage, setUser} from "../store/persisted/persistedActions";
// @ts-ignore
import Logo from '../lib/Ulti_Spirit_Logo_2.png'
import {useNavigate} from "react-router-dom";
import {setStatus} from "../store/ui/status/statusActions";
import {useGoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {selectLanguage} from "../util/UtilFunctions";

const MenuBarComponent: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const stringMap = useSelector(selectLanguage)

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async codeResponse => {
            const tokens = await axios.post(`https://api.ultispirit.com:8080/api/v1/token?code=${codeResponse.code}&grant_type=authorization_code&client_id=922748672818-2dcf8981sgnl84hakpre5e7m6a9jmv4k.apps.googleusercontent.com&redirect_uri=${document.location.origin}`);
            if (tokens.status === 200) {
                dispatch(setStatus({severity: "success", message: stringMap.loggedIn}))
                dispatch(setAPIToken(tokens.data.id_token))
            } else {
                console.log("Error", tokens)
            }
        },
        onError: (errorResponse) => console.log("Error", errorResponse)
    })

    // const { data, getAuth } = useOAuth2<AuthTokenPayloadExtended>({
    //     authorizeUrl: "https://accounts.google.com/o/oauth2/auth",
    //     clientId: "922748672818-2dcf8981sgnl84hakpre5e7m6a9jmv4k.apps.googleusercontent.com",
    //     redirectUri: `${document.location.origin}/callback`,
    //     scope: "email profile openid",
    //     responseType: "code",
    //     exchangeCodeForTokenServerURL: "https://api.ultispirit.com:8080/api/v1/token",
    //     exchangeCodeForTokenMethod: "POST",
    //     onSuccess: (payload) => {
    //         dispatch(setStatus({severity: "success", message: stringMap.loggedIn}))
    //         dispatch(setAPIToken(payload.id_token))
    //     },
    //     onError: (error_) => console.log("Error", error_)
    // });

    const language = useSelector((state: RootState) => state.persisted.language)

    const apiToken = useSelector((state: RootState) => state.persisted.apiToken)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMyTournaments = () => {
        setAnchorEl(null);
        navigate("/admin/tournaments")
    };

    const handleLogout = () => {
        handleClose()
        // localStorage.removeItem("code-https://accounts.google.com/o/oauth2/auth-922748672818-2dcf8981sgnl84hakpre5e7m6a9jmv4k.apps.googleusercontent.com-email profile openid")
        dispatch(setAPIToken(""))
        dispatch(setUser(null))
        dispatch(setStatus({severity: "info", message: stringMap.loggedOut}))
        navigate("/")
    };

    // React.useEffect(() => {
    //     // @ts-ignore
    //     if (data?.id_token) {
    //         // @ts-ignore
    //         dispatch(setAPIToken(data?.id_token))
    //     }
    // }, [data])

    return (
        <AppBar position={"static"} >
            <Toolbar>
                <div style={{marginRight: 20}} onClick={() => navigate("/")}>
                    <img src={Logo} style={{height: 60, width: 60, cursor: "pointer"}}/>
                </div>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        fontWeight: 400,
                        color: '#193B5D',
                        textDecoration: 'none',
                    }}
                >
                    ULTI SPIRIT
                </Typography>
                <Divider orientation="vertical" flexItem variant={"middle"} />
                <Box sx={{ flexGrow: 1 }}>
                    {/*<Button*/}
                    {/*    // onClick={handleCloseNavMenu}*/}
                    {/*    sx={{ my: 2, display: 'block' }}*/}
                    {/*    color={"inherit"}*/}
                    {/*>*/}
                    {/*    {stringMap.tournamentsLabel}*/}
                    {/*</Button>*/}
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                    <div style={{display: "inline-flex"}}>
                        <div style={{marginRight: 6}}>
                            <ToggleButtonGroup
                                value={language}
                                size={"small"}
                                color="standard"
                                exclusive
                                onChange={(event, value) => dispatch(setLanguage(value))}
                            >
                                <ToggleButton value={"EN"}>EN</ToggleButton>
                                <ToggleButton value={"DE"}>DE</ToggleButton>

                            </ToggleButtonGroup>
                        </div>
                        { apiToken === null || apiToken === "" ? (
                            <IconButton color={"secondary"} onClick={() => googleLogin()}>
                                <GoogleIcon/>
                            </IconButton>
                        ) : (
                            <IconButton color={"secondary"} onClick={handleMenu} sx={{ p: 0 }}>
                                <AccountCircleIcon />
                            </IconButton>
                        )}
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleMyTournaments}>{stringMap.myTournamentsLabel}</MenuItem>
                            <MenuItem onClick={handleLogout}>{stringMap.logout}</MenuItem>
                        </Menu>
                    </div>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default MenuBarComponent
