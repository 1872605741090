import {Reducer} from "redux";
import {StatusActions, StatusActionTypes} from "./statusActions";

export interface StatusState {
    severity: "error"| "info" | "warning" | "success";
    message: string | null
}

const defaultState: StatusState = {
    severity: "success",
    message: null
}

export const statusReducer: Reducer<StatusState, StatusActions> = (
    state = defaultState, action: StatusActions
) => {
    switch (action.type) {
        case StatusActionTypes.SET_STATUS:
            return {
                ...action.payload.state
            }
        default:
            return state
    }
}
