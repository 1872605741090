import {Resource, ResourceType} from "./adapters/types";
import {MultiChildSingleRelationAdapterActions} from "./adapters/MultiChildSingleRelationAdapter";
import {get} from "./APIVersion1";
import {useQuery} from "react-query";
import {handleUnauthorized} from "../util/UtilFunctions";
import {setStatus} from "../store/ui/status/statusActions";

export interface SpiritScoreResource extends Resource {
    opponentTeam: string
    opponentTeamName: string
    ratingTeamName: string
    ruleKnowledge: number
    avoidBodyContact: number
    fairMinded: number
    positiveAttitude: number
    communication: number
    ownRuleKnowledge: number
    ownAvoidBodyContact: number
    ownFairMinded: number
    ownPositiveAttitude: number
    ownCommunication: number
    comment: string
}

export class SpiritScoreAdapter {
    actions() {
        const adapterActions =  MultiChildSingleRelationAdapterActions<SpiritScoreResource>("spirit-score", ResourceType.spiritScore)

        const getAllByOpponentTeam = (teamId: string) => {
            return get(`spirit-score/opponent/${teamId}`).then(
                result => {
                    if (result.status === 200) {
                        return result.json().then(values => {
                            return values
                        })
                    } else if (result.status === 401) {
                        handleUnauthorized(adapterActions.dispatch)
                    } else {
                        adapterActions.dispatch(setStatus({severity: "error", message: `${ResourceType.spiritScore} ${adapterActions.stringMap.couldNotBeFound}`}))
                    }
                }
            )
        }

        const useGetAllByOpponentTeam = (teamId: string) => {
            return useQuery([ResourceType.spiritScore, "OPPONENT", teamId], () => getAllByOpponentTeam(teamId))
        }

        return {
            ...adapterActions,
            useGetAllByOpponentTeam
        }
    }
}
