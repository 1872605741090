import {TournamentStatus} from "../TournamentResource";

export enum ResourceType {
    bracket= "bracket",
    group = "group",
    matchUp = "matchUp",
    matchUpReport = "matchUpReport",
    round = "round",
    schedule = "schedule",
    spiritScore = "spiritScore",
    team = "team",
    tournament = "tournament",
    user = "user"
}

export interface Resource {
    id: string
}

export interface UpdateMutation<R> {
    resource: R
    id: string
    teamCode: string
    parentId: string
}

export interface AuthUpdateMutation<R> {
    resource: R
    id: string
    parentId: string
    tournamentId: string | undefined
}

export interface AuthStatusUpdate {
    id: string
    status: TournamentStatus
    parentId: string
}

export interface AddMutation<R> {
    resource: R
    parentId: string
    teamCode: string
}

export interface AddMutationWithParentRelation<R> {
    resource: R
    parentId: string
    parentRelation: string
    teamCode: string
}

export interface AuthAddMutation<R> {
    resource: R
    parentId: string
    tournamentId: string | undefined
}

export interface AuthAddMutationWithParentRelation<R> {
    resource: R
    parentId: string
    parentRelation: string
    tournamentId: string | undefined
}

export interface DeleteMutation {
    id: string
    parentId: string
    teamCode: string
}

export interface AuthDeleteMutation {
    id: string
    parentId: string
    tournamentId: string | undefined
}
